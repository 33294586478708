.contact-detail-page {
    background-color: #eff1f5;
    .tabs-container {
      .ant-tabs-bar {
        border-bottom: none;
        background-color: #fff;
        padding: 0 30px;
      }
      .ant-tabs-nav{
        background: white;
        padding: 5px 20px;
      }
    }

    .summary-container{
      .info-row {
        margin-bottom: 10px;
        .label {
          width: 150px;
          display: table-cell;
          text-align: left;
          color: rgba(0, 0, 0, 0.85);
        }
        .text {
          color: #808080;
          display: table-cell;
          vertical-align: text-top;
          &.text-blue {
            color: #1890ff;
          }
          padding-left: 20px;
        }

        span .fa-phone{
          margin-left: 20px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .price-policy{
      td {
        padding: 0px;
      }
      
      p {
        margin-bottom: 0px;
        padding: 5px 10px;
      }

      hr {
        border: 0.5px solid #f0f0f0;
        margin: 0px;
      }

      &__price {
        text-align: end;
      }
    }
}