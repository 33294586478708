@primary-color: #e8262d;
@link-color: #3498db;

.ant-input-number,
.ant-input,
.ant-select-selector,
.ant-input-affix-wrapper,
.ant-picker {
    width: 100% !important;
    border-radius: 5px !important;
}

.ant-btn {
    border-radius: 5px !important;
}

.ant-bg > .ant-tabs-nav {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
}

.ant-tabs > .ant-tabs-nav {
    background-color: #fff;
    margin-bottom: 0;
}

.nav-space {
    .ant-tabs-nav {
        padding-left: 1rem !important;
    }
}
.nav-space-x {
    .ant-tabs-nav {
        margin: 0 0.75rem !important;
    }
}
.ant-card.ant-card-bordered {
    border-radius: 5px !important;
    border: none !important;
}

.ant-card-body {
    padding: 20px !important;
}
.ant-card.custom-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.ant-list-lg .ant-list-item{
    padding: 0 !important;
}
