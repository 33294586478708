/*=====================================COMMON CLASS=====================================*/

/* PADDING */
each(range(1px,60px,1), {
  .pd-@{index} {
	padding-top: @value;
  }
});

/* PADDING TOP */
each(range(1px,60px,1), {
  .pd-t-@{index} {
    padding-top: @value;
  }
});

/* PADDING RIGHT */
each(range(1px,60px,1), {
  .pd-r-@{index} {
	padding-right: @value;
  }
});

/* PADDING BOTTOM */
each(range(1px,60px,1), {
  .pd-bt-@{index} {
	padding-bottom: @value;
  }
});

/* PADDING LEFT */
each(range(1px,60px,1), {
  .pd-l-@{index} {
	padding-left: @value;
  }
});

each(range(1px,60px,1), {
  .mg-@{index} {
	margin: @value;
  }
});

each(range(1px,60px,1), {
  .mg-t-@{index} {
	margin-top: @value;
  }
});

each(range(1px,60px,1), {
  .mg-r-@{index} {
	margin-right: @value;
  }
});

each(range(1px,60px,1), {
  .mg-bt-@{index} {
	margin-bottom: @value;
  }
});

each(range(1px,60px,1), {
  .mg-l-@{index} {
	margin-left: @value;
  }
});

/*BORDER RADIUS*/
each(range(1px,30px,1), {
  .bd-radius-@{index} {
    border-radius: @value;
  }
});

.resize-none {
  resize: none;
}
.line-height-normal {
  line-height: normal;
}
.no-border {
  border: none;
}
.no-radius {
  border-radius: 0;
}
.not-allowed {
  cursor: not-allowed;
}
.pointer {
  cursor: pointer;
}
.flr {
  float: right;
}
.fll {
  float: left;
}
.clearfix {
  clear: both;
}
.text-yellow {
  color: #f39c12!important;
}
.hide {
  display: none;
}
.text-al-r {
  text-align: right;
}
.bold {
  font-weight: bold;
}
.bold-400 {
  font-weight: 400;
}
.color-red {
  color: red;
}
.bg-red {
  background-color: red;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.width-100pc {
  width: 100%;
}
.list-unstyled {
  margin: 0;
  padding: 0;
}
.font-size-18px {
  font-size: 18px;
}
.font-size-20px {
  font-size: 20px;
}
.width-100px {
  width: 100px;
}
.width-200px {
  width: 200px;
}
.table-responsive {
  overflow-x: auto;
  clear: both;
}
.v-text-top {
  vertical-align: text-top;
}
.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}

.nowrap-css{
  overflow: hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  width:150px;
  display:inline-block;
}

.font-weight-word {
  font-weight: 600;
}

.breadcrumb-wrap-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
  display: inline-block;
  position: relative;
  top: 4px;
}

.icon-quick-edit {
  font-size: 12px;
  color: #505050;
}
.icon-check-quick-edit {
  margin-left: 10px;
}
.blue-link {
  color: #1890FF;
}
.overflow-hidden {
  overflow: hidden;
}
.h-100pc {
  height: 100%;
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.flex-col {
  flex-direction: column;
}
.justify-center {
  justify-content: center;
}
