.order-detail-page {
    .order-detail-header {
        .white-box {
            padding: 20px;
            height: 85px;
            margin-bottom: 15px;
        }

        .block-item {
            >label {
                color: rgba(0, 0, 0, 0.45);
                font-size: 12px;
                display: block;
            }

            >.text {
                color: rgba(0, 0, 0, 0.85);
                font-weight: 600;
            }

            &.code {
                border-right: 1px solid #ccc;
                height: 49px;

                .text {
                    font-size: 14px;
                }
            }

            &.ads {
                .text {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    display: block;
                    width: 100px;
                }
            }

            &.status {
                .order-status {
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }

    .order-status {
        border-radius: 4px;
        padding: 2px 10px;
        font-size: 12px;
        cursor: pointer;
        display: inline-block;

        &.status_NEW {
            background: #1abc9c;
            color: #fff;
        }

        &.status_READY {
            background: #1abc9c;
            color: #fff;
        }

        &.status_DELIVERED {
            background: #27ae60;
            color: #fff;
        }

        &.status_DELIVERING {
            background-color: #e67e22;
            color: #fff;
        }

        &.status_PICKED_UP {
            background-color: #e67e22;
            color: #fff;
        }

        &.status_HOLD {
            color: #fff;
            background-color: #95a5a6;
        }

        &.status_CANCELLED {
            color: #fff;
            background-color: #95a5a6;
        }

        &.status_RETURNED {
            color: #fff;
            background-color: #95a5a6;
        }

        &.status_EXCHANGE {
            color: #fff;
            background-color: #e74c3c;
        }

        &.status_RETURNING {
            color: #fff;
            background-color: #e74c3c;
        }

        &.status_DRAFT {
            color: #fff;
            background-color: #43984A;
        }
    }

    .card-block {
        margin-bottom: 20px;

        .ant-card-head {
            background-color: rgba(250, 250, 250, 1);
        }

        .ant-card-head-title {
            color: rgba(1, 1, 1, 0.85);
            font-weight: 600;
        }

        &.warehouse-note {
            .label {
                width: 120px;
                text-align: left;
                display: inline-block;
            }
        }
    }

    .summary-tabs {
        &.white-box {
            margin-top: 0;
        }

        .ant-tabs-nav .ant-tabs-tab {
            margin: 0 32px 0 20px;
            padding: 12px 0;
        }

        .ant-tabs-ink-bar {
            height: 3px;
        }
    }

    .summary {
        padding: 0 20px;

        .product-container {
            overflow: auto;

            .table-order-items {
                width: 100%;

                thead>tr {
                    background-color: rgba(250, 250, 250, 1);
                }

                thead th {
                    padding: 15px 8px;
                    font-weight: 600;
                    color: rgba(0, 0, 0, 0.85);
                    text-align: left;

                    @media only screen and (min-width: 1025px) {
                        padding: 15px;
                    }

                    .currency {
                        color: rgba(0, 0, 0, 0.25)
                    }
                }

                tbody>tr>td {
                    padding: 10px 8px;
                    border-bottom: 1px solid #f1f1f1;

                    @media only screen and (min-width: 1025px) {
                        padding: 10px 15px;
                    }

                    &.no-border-bottom {
                        border-bottom: 0;
                    }
                }

                .text-blue {
                    color: rgba(24, 144, 255, 1);
                }
            }
        }

        .shipping-address-form,
        .shipping-form {
            .form-text-mode {
                .ant-form-item {
                    margin-bottom: 0;
                }

                .ant-form-item-label {
                    text-align: left;
                }
            }
        }
    }

    .logs-container {
        padding: 0 20px;
    }

    .form-item-text {
        color: #000;
    }

    .not-allowed {
        .ant-checkbox-input {
            cursor: not-allowed;
        }
    }
}