.contact{
    &-filter{
        label {
            font-weight: 600;
        }
        &__keyword, &__campaign, &__created_at, &__assigned_at, &__stage, &__callTime, &__call_status {
            margin-top: 5px;
            border-radius: 5px;
            margin-bottom: 15px;
        }
        &__campaign .ant-select-selector, &__stage .ant-select-selector, &__call_status .ant-select-selector{
            border-radius: 5px !important;
        }
        &__calls, &__total_call_value, &__total_call_from, &__total_call_to{
            margin-top: 5px;
        }
        &__calls .ant-select-selector{
            border-top-left-radius: 5px !important;
            border-bottom-left-radius: 5px !important;
        }
        &__total_call_value, &__total_call_to{
            border-bottom-right-radius: 5px !important;
            border-top-right-radius: 5px !important;
        }
        &__button_filter, &__button_clear{
            border-radius: 5px;
        }
    }
}